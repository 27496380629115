// src/components/MainContent.js
import React, { useEffect } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger'; 
import Scrollbar from 'smooth-scrollbar';
import CircleType from 'circletype';

import { ReactComponent as Blob1 } from "../assets/blobanimation.svg";
import { ReactComponent as Blob2 } from "../assets/blobanimation2.svg";
import { ReactComponent as Blob3 } from "../assets/blobanimation3.svg";
import { ReactComponent as Blob4 } from "../assets/blobanimation4.svg";
import { ReactComponent as LinkedInLogo } from "../assets/linkedin-in.svg";
import { ReactComponent as InstagramLogo } from "../assets/instagram.svg";
import { ReactComponent as EmailLogo} from "../assets/envelope-regular.svg";


const MainContent = () => {
  useEffect(() => {
    // GSAP Timeline loader

    const t1 = gsap.timeline();

    t1.from(".loader", {
        y: 100,
        duration: 1.4,
        ease: "power4.out",
        delay: 1.4,
        opacity: 0
    },
    "-=1")

    t1.from(".loader2", {
        opacity:0,
        duration: 2,
     }, 
     "-=.8")


    // gsap.to('.title-out', {
    //     x: '-100%', // Move the element to the left of the screen
    //     opacity: 0, // Optionally fade out the element
    //     ease: 'power2.inOut', // Use a specific easing function
    //     scrollTrigger: {
    //         trigger: '.title-out', // Trigger on the landing section
    //         start: 'bottom center', // Start when the top of the landing section is 80% in view
    //         // end: 'bottom 20%', // End when the bottom of the landing section is 20% in view
    //         scrub: 0.5, // Adjust the scrub value to lock the scroll animation to the scroll bar
    //         markers: true
    //     },
    // });

    // Circular Text scroll cue
    const text = document.getElementById('rotated')
    const text2 = document.getElementById('rotated2')
    const rotate = new CircleType(text).radius(70);
    const rotate2 = new CircleType(text2).radius(70);

    window.addEventListener('scroll', function(){
        text.style.transform = 'rotate(' + (window.scrollY * 0.15) + 'deg)'
      })
      window.addEventListener('scroll', function(){
        text2.style.transform = 'rotate(' + (window.scrollY * 0.15) + 'deg)'
      })


    //GSAP ScrollTrigger and Smooth Scrollbar

    gsap.registerPlugin(ScrollTrigger);
    
    // smooth scroll effect
    const scrollBar = Scrollbar.init(document.querySelector('.main'), {
        damping: 0.06,
        delegateTo: document,
        alwaysShowTracks: false,
        speed: 6,
    });
    ScrollTrigger.defaults({
        scroller: '.main',
    });
    ScrollTrigger.scrollerProxy('.main', {
        scrollTop(value) {
            if (arguments.length) {
                scrollBar.scrollTop = value;
            }
            return scrollBar.scrollTop;
        },
    });

    scrollBar.addListener(ScrollTrigger.update);

    // functions to adjust background color/text color
    function update_bg_forward(section) {
        gsap.to(
            '.main', {
                backgroundColor: section.dataset.bgcolor, 
                color: section.dataset.textcolor, 
                overwrite: 'auto',
            });
        const loadElements = section.querySelectorAll('.load');
        const header_loadElements = section.querySelectorAll('.hload');
        const slowLoad = section.querySelectorAll('.slow-load');

        // Use GSAP to animate each load element
        gsap.fromTo(
            loadElements,
            { opacity: 0, y: 50 }, // Starting state
            {
                opacity: 1, // Ending state
                y: 0,
                duration: 0.7,
                stagger: 0.4,
            }
        );

        gsap.fromTo(
            header_loadElements,
            { opacity: 0, y: 50 }, // Starting state
            {
                opacity: 1, // Ending state
                y: 0,
                duration: 0.5,
                stagger: 0,
                delay: 0.5,
            }
        );

        gsap.to(
            slowLoad,
            {
                opacity: 1,
                duration: 1.5,
                delay: 2,
            }
        );

        const leaveElements = section.querySelectorAll('.leave');
        gsap.to(leaveElements, {
            opacity: 0,
            duration: 1,
        });
    }

    function update_bg_backward(section, prevBackgroundColor, prevTextColor) {
        gsap.to('.main', {
            backgroundColor: prevBackgroundColor,
            color: prevTextColor,
            overwrite: 'auto',
        });
        const loadElements = section.querySelectorAll('.load');
        const header_loadElements = section.querySelectorAll('.hload');
        const slowLoad = section.querySelectorAll('.slow-load');
        gsap.to(loadElements,
            {
                opacity: 0,
                duration: 1,
            })
        gsap.to(header_loadElements,
            {
                opacity: 0,
                duration: 1,
            })
        gsap.to(slowLoad,
            {
                opacity: 0,
                duration: 1,
            })
    }

    function on_leave(section) {
        const leaveElements = section.querySelectorAll('.leave');
        gsap.to(leaveElements,
            {
                opacity: 0,
                duration: 1,
            })
    }

    


    //control sequence for section color changes
    const sectionColor = document.querySelectorAll('[data-bgcolor]');
    sectionColor.forEach((section, i) => {
        //if index 0, do nothing, else store color value for previous
        const prevBackgroundColor = i === 0 ? '' : sectionColor[i-1].dataset.bgcolor;
        const prevTextColor = i === 0 ? '' : sectionColor[i-1].dataset.textcolor;

        ScrollTrigger.create({
            trigger: section,
            scroller: '.main',
            start: 'top 50%',
            onEnter: () => 
                update_bg_forward(section),
            onLeave: ()=> 
                on_leave(section),
            onLeaveBack: () => 
                update_bg_backward(section, prevBackgroundColor, prevTextColor),
            });

        });

        return () => {};
  }, []);

  return (
 
        <div className="main h-screen w-full overflow-x-hidden bg-grain">
            {/* Landing Section: */}
            <section className="landing min-h-screen w-full relative flex flex-col items-center font-alt-display "
            data-bgcolor="#C9C3BD"
            data-textcolor="#000000">
                <div className = "grain absolute w-full h-full z-40 bg-grain"></div>
                <h1 className="loader self-center title-out text-[14vw] md:text-[12.5vw] mt-64 z-30">JUN SIMONS</h1>
                <div className="blur-landing w-100 h-full z-10 bg-[#f7f3ed] absolute">
                    <div className="blobs absolute z-10 min-w-full flex flex-col">
                        
                        <Blob3 style={{position: 'absolute', width: '700px', height: '700px', alignSelf:'center', marginLeft: '-600px'}}/>
                        
                        <Blob4 style={{position: 'absolute', width: '700px', height: '700px', alignSelf:'center', marginLeft: '600px'}}/>
                        
                        <Blob2 style={{position: 'absolute', width: '500px', height: '600px', alignSelf:'center', marginLeft: '-200px', marginTop: '200px'}}/>
                        
                        <Blob1 style={{position: 'absolute', width: '700px', height: '700px',alignSelf:'center', marginLeft: '400px' }}/>
                    </div>
                    {/* <div className="blobs relative z-10 min-w-full flex flex-col">
                        
                        <Blob3 style={{position: 'relative', width: '700px', height: '700px', alignSelf:'center', marginLeft: '7%'}}/>
                        
                        <Blob4 style={{position: 'relative', width: '700px', height: '700px', alignSelf:'center', marginLeft: '12%'}}/>
                        
                        <Blob2 style={{position: 'relative', width: '500px', height: '600px', alignSelf:'center', marginLeft: '7%', marginTop: '5%'}}/>
                        
                        <Blob1 style={{position: 'relative', width: '700px', height: '700px',alignSelf:'center', marginLeft: '10%' }}/>
                    </div> */}
                </div>
                <div class="loader2 wheel animate-spin-slow circular-text absolute z-20 mr-[0%] md:mr-[74%] lg:mr-[84%] xl:mt-[40%] lg:mt-[50%] md:mt-[70%] sm:mt-[90%] mt-[80%] ">
                    <span className = "animate-spin" id="rotated">
                        scroll • scroll • scroll • scroll • scroll • scroll • 
                    </span>
                </div>
            </section>

            {/* About Me Section: */}
            <section className="min-h-[110vh] w-screen relative flex flex-col items-center"
            data-bgcolor="#C9C3BD"  
            data-textcolor="#121111">
                <div class="slow-load  opacity-0 wheel animate-spin-slow circular-text absolute z-20 ml-[84%] mt-[45%]">
                        <span className = "font-alt-display animate-spin" id="rotated2">
                            scroll • scroll • scroll • scroll • scroll • scroll • 
                        </span>
                    </div>

                <div className="w-3/5">
                    <h2 className="load opacity-0 mt-32 font-display-lite text-3xl md:text-4xl text-left leading-12">
                        I'm a sophomore at <b>Rensselaer Polytechnic Institute</b> studying Computer Science, 
                        where I'm both <i>focusing on my fundamentals</i> and learning about <i>cutting-edge technologies</i>.
                    </h2>
                    <h2 className="load opacity-0 mt-10 font-display-lite text-3xl md:text-4xl text-left leading-12">
                        My top skills include Python, C++, AWS, Docker, Kubernetes, Git, and React, and I am <i>Scrum & AWS Certified </i>
                    </h2>
                    <h2 className="load opacity-0 mt-10 font-display-lite text-3xl md:text-4xl text-left leading-12">
                        At <b>Johnson and Johnson</b> I built containerized web apps, optimized HPC clusters, reduced AWS spending,
                        and implemented auto-QC for digital pathology images.
                    </h2>
                    <h2 className="load opacity-0 mt-10 font-display-lite text-3xl md:text-4xl text-left leading-12">
                        I also like playing with hardware, and using engineering to make art!
                    </h2>

                    
                </div>
            </section>

            {/* Projects Section: */}
            <section className="mt-20 min-h-[75vh] w-screen relative flex flex-col justify-left px-32"
            data-bgcolor="#141414"
            data-textcolor="#ebe8e6">
                <h2 className="hload opacity-0 font-display text-8xl ">Projects</h2>
                <a href="https://github.com/jun-simons/personal-site" className="hload hover:text-amber-800 opacity-0 mt-12 font-display-lite text-4xl text-left leading-12">
                    Personal Website - built with React, TailwindCSS, and GSAP
                </a> 
                <a href="https://jun-simons.github.io/HTML/typing.html" className="hload  hover:text-amber-800 opacity-0 mt-12 font-display-lite text-4xl text-left leading-12">
                    Dynamic Typing Test - JavaScript
                </a>
                <a href="https://github.com/jun-simons/javasortvis" className="hload hover:text-amber-800 opacity-0 mt-12 font-display-lite text-4xl text-left leading-12">
                    Sorting Algorithm Visualizer - Java
                </a>
               
                <h2 className="hload opacity-0 mt-12 font-display-lite text-4xl text-left ">
                [ More coming soon :&#41; ]
                </h2>
            </section>
            
            {/* Contact Section: */}
            <section className="min-h-[132vh] w-screen relative flex flex-col items-center justify-center overflow-x-hidden overflow-y-clip"
             data-bgcolor="#dbd8d5"  
             data-textcolor="#121111">
                <div className="relative flex z-100 overflow-x-hidden overflow-y-hidden">
                    <div className="py-15  font-display animate-marquee whitespace-nowrap">
                        <span className="mx-4 text-xxl">Contact Me  •</span>
                        <span className="mx-4 text-xxl">Contact Me  •</span>
                        <span className="mx-4 text-xxl">Contact Me  •</span>
                    </div>

                    <div className="absolute font-display top-0 py-15 animate-marquee2 whitespace-nowrap">
                        <span className="mx-4 text-xxl">Contact Me  •</span>
                        <span className="mx-4 text-xxl">Contact Me  •</span>
                        <span className="mx-4 text-xxl">Contact Me  •</span>
                    </div>
                </div>
                <div className="blur-footer opacity-[50%] w-full h-full z-10 bg-[#f7f3ed] mt-[-10%]">
                    <div className="blobs absolute z-10 min-w-full flex flex-col">
                        
                        <Blob3 style={{position: 'absolute', width: '700px', height: '700px', alignSelf:'center', marginLeft: '-600px'}}/>
                        
                        <Blob4 style={{position: 'absolute', width: '700px', height: '700px', alignSelf:'center', marginLeft: '600px'}}/>
                        
                        <Blob2 style={{position: 'absolute', width: '500px', height: '600px', alignSelf:'center', marginLeft: '-200px', marginTop: '200px'}}/>
                        
                        <Blob1 style={{position: 'absolute', width: '700px', height: '700px',alignSelf:'center', marginLeft: '500px' }}/>
                    </div>
                </div>
                <div className="flex flex-row justify-center space-x-48 z-20 mt-48">
                    <a href="https://www.linkedin.com/in/jun-simons/" className="hload ml-[15%]">
                        <LinkedInLogo className="w-[50%]" />
                    </a>

                    <a href="mailto:jdsimons017@gmail.com" className="hload">
                        <EmailLogo className="w-[50%] mt-[7%]" />
                    </a>
                </div>
                <div className="absolute bottom-0 w-full py-4 z-100 text-black text-center">
                <p className="text-md">Made with React & GSAP - Jun Simons 2024</p>
                </div>  
            </section>
        </div>
  );
};

export default MainContent;
