import React from 'react';
import MainContent from './components/MainContent';
import './App.css';

function App() {
  return (
    <body className="overflow-x-hidden h-full max-w-full w-full min-h-screen bg-[#1f1f1f]">
        
        <MainContent />

    </body>
      
  );
}

export default App;
